.App {
    text-align: center;
    overflow-y: scroll;
  
  }
  
  header {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
  }
  
  span {
    display: block;
    color: #5981F3;
    padding-left: 1px;
  }

  li {
    display: inline;
    padding: 20px;
  }
  
  .item {
    height:30px;
  
  }
  .logo{
    width: 40px;
    height: 40px;
    padding-right: 20px;
  }
  
  .fulllogo{
    width: 350px;
    height: 100px;
    padding-right: 20px;
  }
    
  .eth{
    width: 20px;
    height: 20px;
    padding-right: 10px;
  
  }
  
  .leftH {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .rightH {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
  
  .headerItem {
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    font-weight: 500;
    transition: 0.3s;
    display: flex;
    align-items: center;
  }
  
  .headerItem:hover {
    background-color: rgb(34, 42, 58);
    cursor: pointer;
  }
  
  .connectButton {
    background-color: #243056; 
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 100px;
    color: #5981F3;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .connectButton:hover {
    cursor: pointer;
    color: #3b4874
  }
  
  .mainWindow {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  
  .link {
    text-decoration: none;
    color: white;
    font-size: x-large;
  }
  
  details {
    font:
      16px "Open Sans",
      inherit,
      sans-serif;
    width: 500px;
  }
  
  details > summary {
    padding: 10px 6px;
    width: 50em;
    text-align: left;
  /*  background-color: #ddd; */
    border: none;
  /*  box-shadow: 3px 3px 4px black;*/
    cursor: pointer;
  }
  
  details > p {
    border-radius: 10px 10px 10px 10px;
  /*  background-color: #ddd; */
    padding: 10px 10px;
    margin: 0;
    text-align: left;
    font:
      16px "Open Sans",
      inherit,
      sans-serif;
  
    /*  box-shadow: 3px 3px 4px black;*/
  }
  
  details[open] > summary {
    background-color: inherit;
  }
  
  .tradeBox {
    width: 400px;
    background-color: #0E111B;
    border: 2px solid #21273a;
    min-height: 300px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 30px;
    padding-right: 30px;
  }
  
  
  .tradeBoxHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
  }
  
  .cog {
    color: #51586f;
    transition: 0.3s;
  }
  
  .cog:hover {
    color: white;
    rotate: 90deg;
    cursor: pointer;
  }
  
  .switchButton {
    background-color: #3a4157;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 8px;
    position: absolute;
    top: 86px;
    left: 180px;
    color: #5F6783;
    border: 3px solid #0E111B;
    font-size: 12px;
    transition: 0.3s
  }
  
  .dollarAmount {
    position: absolute;
    top: 75px;
    left: 14px;
    font-size: 10px;
    color: #5F6783;
  }
  
  .switchButton:hover {
    color: white;
    cursor: pointer;
  }
  
  .wrapper {
    display: flex;
  }
  .inputs {
    position: relative;
  }
  
  .searchClear {
    position: absolute;
    min-width: 50px;
    height: 30px;
    background-color: #3a4157;
    top: 39.5%;
    left: 56%;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    font-size: 17px;
    padding-right: 8px;
  }
  .assetOne {
    position: absolute;
    min-width: 50px;
    height: 30px;
    background-color: #3a4157;
    top: 36px;
    left: 10px;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    font-size: 17px;
    padding-right: 8px;
  }
  
  .assetTwo {
    position: absolute;
    min-width: 50px;
    height: 30px;
    background-color: #3a4157;
    top: 135px;
    left: 10px;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    font-size: 17px;
    padding-right: 8px;
  }
  
  .gasFee {
    position: absolute;
    min-width: 50px;
    height: 30px;
    background-color: #3a4157;
    top: 235px;
    left:10px;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    font-size: 17px;
    padding-right: 8px;
  }

  .liquiditySource {
    position: absolute;
    min-width: 50px;
    height: 30px;
    background-color: #3a4157;
    top: 305px;
    left: 10px;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    font-size: 17px;
    padding-right: 8px;
  }
  .assetOne:hover{
    cursor: pointer;
  }
  
  .assetTwo:hover{
    cursor: pointer;
  }
  
  .assetLogo {
    height: 22px;
    margin-left: 5px;
  }
  
  .swapButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #243056; 
    width: calc(100%);
    height: 55px;
    font-size: 20px;
    border-radius: 12px;
    color: #5981F3;
    font-weight: bold;
    transition: 0.3s;
    margin-bottom: 30px;
    margin-top: 8px;
  }
  
  .swapButton[disabled] {
    background-color: #243056;
    opacity: 0.4;
    color: #5982f39b;
  }
  
  .swapButton[disabled]:hover {
    cursor: not-allowed;
    background-color: #243056;
  }
  
  .swapButton:hover {
    cursor: pointer;
    background-color: #3b4874
  }
  
  .tokenLogo {
    height: 40px;
    width: 40px;
  }
  
  .modalContent {
    border-top: 1px solid #363e54;
    /* padding-top: 20px; */
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px
  }
  
  .tokenChoice {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .tokenChoice:hover {
    cursor: pointer;
    background-color: #1f2639;
  }
  
  .tokenName {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  
  .tokenTicker {
    margin-left: 10px;
    font-size: 13px;
    font-weight: 300;
    color: #51596f;
  }
 
  .chainSelect {
    position: absolute;
    min-width: 50px;
    height: 30px;
    background-color: #3a4157;
    top: 36px;
    right: 180px;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    font-size: 17px;
    padding-right: 8px;
  }

  .chainChoice {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .chainChoice:hover {
    cursor: pointer;
    background-color: #1f2639;
  }
  
  #elementor-widget-container {
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);
    display:inline;  
        
  }
  .elementor-widget-icon-box .elementor-icon-box-title a {
    color: inherit
  }
  
  .elementor-widget-icon-box .elementor-icon-box-content {
    flex-grow: 1
  }
  
  .elementor-widget-icon-box .elementor-icon-box-description {
    margin: 0
  }
  
  .elementor-widget.elementor-icon-list--layout-inline .elementor-widget-container {
    overflow: hidden
  }
  
  .elementor-widget .elementor-icon-list-items.elementor-inline-items {
    margin-right: -8px;
    margin-left: -8px
  }
  
  .elementor-widget .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item {
    margin-right: 8px;
    margin-left: 8px
  }
  
  .elementor-widget .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    width: auto;
    left: auto;
    right: auto;
    position: relative;
    height: 100%;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-left-width: 1px;
    border-style: solid;
    right: -8px
  }
  
  #elementor-widget .elementor-icon-list-items {
    list-style-type: none;
    display:inline;      
    margin: 0;
    padding: 0
  }
  
  .elementor-widget .elementor-icon-list-item {
    margin: 0;
    padding: 0;
    position: relative
  }
  
  .elementor-widget .elementor-icon-list-item:after {
    position: absolute;
    bottom: 0;
    width: 100%
  }
  
  .elementor-widget .elementor-icon-list-item,.elementor-widget .elementor-icon-list-item a {
    display: flex;
    font-size: inherit;
    align-items: var(--icon-vertical-align,center)
  }
  
  .elementor-widget .elementor-icon-list-icon+.elementor-icon-list-text {
    align-self: center;
    padding-left: 5px
  }
  
  .elementor-widget .elementor-icon-list-icon {
    display: flex;
    position: relative;
    top: var(--icon-vertical-offset,initial)
  }
  
  .elementor-widget .elementor-icon-list-icon svg {
    width: var(--e-icon-list-icon-size,1em);
    height: var(--e-icon-list-icon-size,1em)
  }
  
  .elementor-widget .elementor-icon-list-icon i {
    width: 1.25em;
    font-size: var(--e-icon-list-icon-size)
  }
  
  .elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon {
    text-align: var(--e-icon-list-icon-align)
  }
  
  .elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon svg {
    margin: var(--e-icon-list-icon-margin,0 calc(var(--e-icon-list-icon-size, 1em) * .25) 0 0)
  }
  
  .elementor-widget.elementor-list-item-link-full_width a {
    width: 100%
  }
  
  .elementor-widget.elementor-align-center .elementor-icon-list-item,.elementor-widget.elementor-align-center .elementor-icon-list-item a {
    justify-content: center
  }
  
  .elementor-widget.elementor-align-center .elementor-icon-list-item:after {
    margin: auto
  }
  
  .elementor-widget.elementor-align-center .elementor-inline-items {
    justify-content: center
  }
  
  .elementor-widget.elementor-align-left .elementor-icon-list-item,.elementor-widget.elementor-align-left .elementor-icon-list-item a {
    justify-content: flex-start;
    text-align: left
  }
  
  .elementor-widget.elementor-align-left .elementor-inline-items {
    justify-content: flex-start
  }
  
  .elementor-widget.elementor-align-right .elementor-icon-list-item,.elementor-widget.elementor-align-right .elementor-icon-list-item a {
    justify-content: flex-end;
    text-align: right
  }
  
  .elementor-widget.elementor-align-right .elementor-icon-list-items {
    justify-content: flex-end
  }
  
  .elementor-widget:not(.elementor-align-right) .elementor-icon-list-item:after {
    left: 0
  }
  
  .elementor-widget:not(.elementor-align-left) .elementor-icon-list-item:after {
    right: 0
  }
  